import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import {
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "~/styles/index.css";

import { ColorSchemeScript, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { createBrowserClient } from "@supabase/ssr";
import { SupabaseClient, User } from "@supabase/supabase-js";
import { getUser } from "modules/auth/authHelper";
import { useEffect, useState } from "react";
import { getToast } from "remix-toast";
import {
  showErrorToast,
  showSuccessToast,
} from "./components/Notifications/customNotifications";
import { Posthog } from "./posthog";
import { customTheme } from "./styles/theme";
import "./tailwind.css";

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
];

export interface OutletContext {
  supabase: SupabaseClient;
  user: User | null;
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
  if (
    !process.env.SUPABASE_URL ||
    !process.env.SUPABASE_ANON_KEY ||
    !process.env.POSTHOG_KEY
  ) {
    throw new Error(
      "Missing SUPABASE_URL, SUPABASE_ANON_KEY or POSTHOG_KEY environment variables"
    );
  }

  const { toast: toastObj, headers: toastHeaders } = await getToast(request);
  const env = {
    SUPABASE_URL: process.env.SUPABASE_URL,
    SUPABASE_ANON_KEY: process.env.SUPABASE_ANON_KEY,
    POSTHOG_KEY: process.env.POSTHOG_KEY,
  };

  const { user, headers: supabaseHeaders } = await getUser(request);

  // Merge headers
  const headers = new Headers();
  supabaseHeaders.forEach((value, key) => {
    headers.set(key, value);
  });
  toastHeaders.forEach((value, key) => {
    headers.set(key, value);
  });

  return json({ env, toastObj, user }, { headers });
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <div className="flex items-center justify-center h-full">
          <div className="text-2xl font-bold">
            Something went wrong. Please refresh the page.
          </div>
        </div>
      </body>
    </html>
  );
};

export default function App() {
  const { env, toastObj, user: initialUser } = useLoaderData<typeof loader>();
  const [user, setUser] = useState(initialUser);

  useEffect(() => {
    if (toastObj?.type === "error") {
      showErrorToast(toastObj.message);
    }
    if (toastObj?.type === "success") {
      showSuccessToast(toastObj.message);
    }
  }, [toastObj]);

  const [supabase] = useState(() =>
    createBrowserClient(env.SUPABASE_URL, env.SUPABASE_ANON_KEY)
  );

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (session?.user) {
        setUser(session.user);
      } else {
        setUser(null);
      }
    });

    return () => subscription.unsubscribe();
  }, [supabase]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <ColorSchemeScript />
      </head>
      <body id="app">
        <MantineProvider theme={customTheme}>
          <Notifications />
          <Outlet
            context={{
              supabase,
              user,
            }}
          />
          <ScrollRestoration />
          <Posthog posthogKey={env.POSTHOG_KEY} user={user} />
          <Scripts />
        </MantineProvider>
      </body>
    </html>
  );
}
